import classNames from 'classnames';

const Radio = ({ color, id, name, checked, ...rest }) => {

  let className = classNames(`relative h-5 w-5 border-2 !bg-transparent border-white cursor-pointer appearance-none rounded-full
  !border-black dark:!border-white dark:checked:!bg-none checked:!bg-none !bg-none`, {"dark:!border-blue-500": checked})

  return (
    <input
      id={id}
      name={name}
      type="radio"
      className={className}
      {...rest}
    />
  );
};

export default Radio;
