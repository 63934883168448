import React from "react";

// Admin Imports
import PersonalProfile from "views/profile";
import Organization from "views/organization";
import NFTMarketplace from "views/admin/marketplace";
// import Profile from "views/profile";
import DataTables from "views/admin/tables";
import { MdMessage } from 'react-icons/md';
// Auth Imports
import SignIn from "views/auth/SignIn";
import BulkMessenger from "views/messages/BulkMessenger";
// Icon Imports
import {
  MdBarChart,
  MdPerson,
  MdOutlineCloud,
  MdOutlineTask,
  MdBook
} from "react-icons/md";

import { SlBadge } from "react-icons/sl";

import { AiOutlinePieChart, AiOutlineUser, AiOutlinePlayCircle, AiOutlineLogout    } from "react-icons/ai";
import Assessments from "views/assessments";
import CurrentAssessment from "views/currentAssessment";
import AssessmentResults from "views/assessmentResults";
import ECourse from "views/ecourse";
import MyEcourse from "views/myEcourse";

const iconMap = {
  mdBarChartIcon: <MdBarChart className="h-6 w-6" />,
  mdPersonIcon: <MdPerson className="h-6 w-6" />,
  mdCloudIcon: <MdOutlineCloud className="h-6 w-6" />,
  mdTaskIcon: <MdOutlineTask className="h-6 w-6" />,
  slBadgeIcon: <SlBadge className="h-6 w-6" />,
  aiPieChartIcon: <AiOutlinePieChart className="h-6 w-6" />,
  aiUserIcon: <AiOutlineUser className="h-6 w-6" />,
  aiPlayCircleIcon: <AiOutlinePlayCircle className="h-6 w-6" />,
  aiLogoutIcon: <AiOutlineLogout className="h-6 w-6" />,
}

const componentMap = {
  personalProfile: <PersonalProfile />,
  organization: <Organization />,
  assessments: <Assessments />,
  currentAssessment: <CurrentAssessment />,
  assessmentResults: <AssessmentResults />,
  dataTables: <DataTables />,
}

const routes = [
  {
    name: "Home",
    layout: "/",
    path: "home",
    icon: <AiOutlinePieChart className="h-6 w-6" />,
    component: <PersonalProfile />,
  },
  {
    name: "Personal Profile",
    layout: "/",
    path: "profile",
    icon: <AiOutlineUser className="h-6 w-6" />,
    component: <PersonalProfile />,
  },
  {
    name: "Videos",
    layout: "/",
    path: "videos",
    icon: <AiOutlinePlayCircle  className="h-6 w-6" />,
    component: <PersonalProfile />,
  },
  {
    name: "Organization",
    layout: "/",
    path: "organization",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Organization />,
  },
  // {
  //   name: "Assessments",
  //   layout: "/",
  //   path: "assessments",
  //   icon: <MdOutlineCloud className="h-6 w-6" />,
  //   component: <Assessments />,
  //   secondary: true,
  // },
  {
    name: "Bulk Messenger",
    layout: "/",
    path: "messages",
    icon: <MdMessage className="h-6 w-6" />,
    component: <BulkMessenger />  
},
  {
    name: "Current Assessment",
    layout: "/",
    path: "current-assessment",
    icon: <MdOutlineTask className="h-6 w-6" />,
    component: <CurrentAssessment />,
    secondary: true,
  },
  {
    name: "Aseessment Results",
    layout: "/",
    path: "assessment-results",
    icon: <SlBadge className="h-6 w-6" />,
    component: <AssessmentResults />,
    secondary: true,
  },
  {
    name: "E-Course Creator",
    layout: "/",
    icon: <MdBook className="h-6 w-6" />,
    path: "ecourse-creator",
    component: <ECourse />,
  },
  {
    name: "My E-Courses",
    layout: "/",
    icon: <MdBook className="h-6 w-6" />,
    path: "my-ecourse",
    component: <MyEcourse />,
  },

];

export default routes;
