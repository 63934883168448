/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserProfile = /* GraphQL */ `
  query GetUserProfile($input: GetUserProfileInput!) {
    getUserProfile(input: $input) {
      id
      firstName
      lastName
      email
      phoneNumber
      gender
      birthDate
      image
      questionData {
        key
        result
        questions
        questionNum
        totals
        chosenValues
        results
        __typename
      }
      __typename
    }
  }
`;
export const getOrgAssessments = /* GraphQL */ `
  query GetOrgAssessments($input: GetOrgAssessmentsInput!) {
    getOrgAssessments(input: $input) {
      introduction
      syllabus
      video
      key
      name
      image
      description
      type
      __typename
    }
  }
`;
export const getAllAssessments = /* GraphQL */ `
  query GetAllAssessments {
    getAllAssessments {
      introduction
      syllabus
      video
      id
      title
      image
      description
      type
      finishedDirections
      calculate
      questions {
        question
        answers {
          label
          value
          __typename
        }
        __typename
      }
      results {
        key
        name
        link
        image
        description
        __typename
      }
      __typename
    }
  }
`;
export const getAssessment = /* GraphQL */ `
  query GetAssessment($input: getAssessmentInput!) {
    getAssessment(input: $input) {
      introduction
      syllabus
      video
      id
      title
      image
      description
      type
      finishedDirections
      calculate
      questions {
        question
        answers {
          label
          value
          __typename
        }
        __typename
      }
      results {
        key
        name
        link
        image
        description
        __typename
      }
      __typename
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($input: getOrganizationInput!) {
    getOrganization(input: $input) {
      id
      name
      address {
        street
        city
        state
        zip
        __typename
      }
      organizationType
      category
      email
      phone
      adminEmails
      logoImage
      backgroundImage
      titleImage
      assessments {
        introduction
        syllabus
        video
        key
        name
        image
        description
        type
        __typename
      }
      routes
      createdAt
      updatedAt
      __typename
    }
  }
`;
