import CourseForm from "./components/CourseForm";

const ECourse = () => {
    return (
        <div>
            <CourseForm />
        </div>
    )
}

export default ECourse;