import ProfileCard from "./components/ProfileCard";
import AssesmentCard from "components/card/AssesmentCard";
import { TextField, useAuthenticator } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { generateClient } from "aws-amplify/api";
import { getUserProfile, getOrgAssessments } from "graphql/queries";
import { useDispatch, useSelector } from "react-redux";
import { setKey, setUserId, setProfileImage, setEcourseKey } from "../../store";
import { useNavigate } from "react-router-dom";
import { Bars } from "react-loading-icons";
import { fetchUserAttributes } from "aws-amplify/auth";
import { FaPencilAlt, FaPlus } from "react-icons/fa";
// import EcourseCard from "components/card/EcourseCard";

const client = generateClient();

const Profile = () => {
  const { user } = useAuthenticator()
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(false);
  const { orgId, adminEmails, ecourses } = useSelector((state) => state.organization);

  const [userData, setUserData] = useState({});
  const [orgAssessment, setOrgAssessment] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    console.log("USER", user)
    const fetchUserDetails = async () => {
      const { email } = await fetchUserAttributes();
      const userProfile = await client.graphql({
        query: getUserProfile,
        variables: {
          input: {
            email: email,
            orgName: orgId
          },
        },
      });
      if (userProfile.data.getUserProfile) {
        setUserData(userProfile.data.getUserProfile);
        dispatch(setUserId(userProfile.data.getUserProfile.firstName))
        dispatch(setProfileImage(userProfile.data.getUserProfile.image))
      }
    };
    fetchUserDetails();
    setLoading(false);
  }, [user, updating]);

  useEffect(() => {
    const fetchAssessments = async () => {
      const assessment = await client.graphql({
        query: getOrgAssessments,
        variables: {
          input: {
            orgId,
          },
        },
      });
      if (assessment.data.getOrgAssessments) {
        setOrgAssessment(assessment.data.getOrgAssessments);
      }
    };
    fetchAssessments();
  }, []);

  const navigate = useNavigate();

  // function assessmentClick(assessmentKey) {
  //   dispatch(setKey(assessmentKey));
  //   navigate("/current-assessment");
  // }

  function ecourseClick(ecourseKey, isEdit) {
    dispatch(setKey(ecourseKey));
    if (!isEdit) {
      navigate("/my-ecourse");
    }
    else {
      navigate("/my-ecourse", { state: { edit: true } });
    }
  }

  console.log("USER DATA FROM PROFILE", userData)

  return (
    <div>
      <ProfileCard userData={userData} setUserData={setUserData} setUpdating={setUpdating} />
      {loading || !orgAssessment ? (
        <Bars className="mt-4 mb-4 h-20 w-full items-center" fill="#D0CECF" />
      ) : (
        <div className="my-4 bg-white px-3 lg:px-6 xl:px-8 py-[20px] dark:!bg-navy-800 dark:shadow-none dark:hover:!bg-navy-700">
          {console.log("ADMIN EMAILS", adminEmails)}
          {adminEmails.includes(userData.email) && (
            <div className="pb-4 flex justify-end space-x-4">
              <button
                onClick={() => navigate("/ecourse-creator")}
                className="linear space-x-1 flex items-center justify-center rounded-[20px] bg-white px-4 py-2 text-base font-medium text-black transition duration-200 hover:bg-gray-200 active:bg-gray-400 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:opacity-90"
              >
                <span>
                  <FaPlus className="h-4 w-4" />
                </span>
                <span>Create</span>
              </button>
            </div>
          )}
          <div className="flex flex-row flex-wrap gap-y-4 gap-x-3 items-start justify-center md:justify-start xl:justify-start wxl:justify-around">
          {orgAssessment.map((assessment, key) => (
              <AssesmentCard
                key={key}
                assessmentKey={assessment.key}
                title={assessment.name}
                label={assessment.type}
                image={assessment.image}
                result={
                  userData?.questionData?.find(
                    (userAssessment) => userAssessment.key === assessment.key
                  )?.result
                }
                extra="single-card"
              >
                <div className="flex space-x-4 items-center justify-between md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col 2xl:items-start 3xl:flex-row 3xl:items-center 3xl:justify-between">
                  <button
                    onClick={() => ecourseClick(assessment.key, false)}
                    className="linear flex h-full w-full items-center justify-center rounded-[20px] bg-white px-4 py-2 text-base font-medium text-black transition duration-200 hover:bg-gray-200 active:bg-gray-400 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:opacity-90"
                  >
                    View
                  </button>
                  {adminEmails.includes(userData.email) && (
                    <button
                      onClick={() => ecourseClick(assessment.key, true)}
                      className="linear flex h-full w-full items-center justify-center rounded-[20px] bg-white px-4 py-2 text-base font-medium text-black transition duration-200 hover:bg-gray-200 active:bg-gray-400 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:opacity-90"
                    >
                      Edit
                    </button>
                  )}

                </div>
              </AssesmentCard>
            ))}
          </div>
        </div>
      )}

      <div className="mb-6 grid gap-6 bg-white px-12 py-[20px] dark:!bg-navy-800 dark:shadow-none dark:hover:!bg-navy-700 md:grid-cols-2">
        <TextField
          label="Date of Birth"
          placeholder={userData.birthDate}
          extra="w-full"
        />
        <TextField
          label="Gender"
          placeholder={userData.gender}
          extra="w-full"
        />
      </div>
    </div>
  );
};

export default Profile;
