import { createSlice } from "@reduxjs/toolkit";
import routes from "../../routes";

const organizationSlice = createSlice({
  name: "organization",
  initialState: {
    orgId: "",
    name: "",
    bannerImage: "",
    adminEmails: [],
    routes: [],
    ecourses: [],
  },
  reducers: {
    setOrgId: (state, action) => {
      state.orgId = action.payload;
    },
    setOrgBannerImage: (state, action) => {
      state.bannerImage = action.payload;
    },
    setOrgName: (state, action) => {
      state.name = action.payload;
    },
    setAdminEmails: (state, action) => {
      state.adminEmails = action.payload;
    },
    setRoutes: (state, action) => {
      state.routes = routes.filter((route) => action.payload.includes(route.path));
    },
    setEcourses: (state, action) => {
      state.ecourses = action.payload;
    },
  },
});

export const { setOrgId, setOrgName, setAdminEmails, setOrgBannerImage, setRoutes, setEcourses } =
  organizationSlice.actions;
export const organizationReducer = organizationSlice.reducer;
