import React, { useState } from "react";
import { DropZone, PhoneNumberField, TextField } from "@aws-amplify/ui-react";
import avatar from "assets/img/avatars/avatarUser.jpg";
import { Formik } from "formik";
import { updateUserProfile } from "graphql/mutations";
import { generateClient } from "aws-amplify/api";
import { FaPencilAlt } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import { useDispatch, useSelector } from "react-redux";
import { setProfileImage } from "store";
// import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
// import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";

// Configure S3 client
const REGION = 'us-east-1';  
const BUCKET_NAME = 'backendstack-v2-imagesbucketnewc7a361fc-qjm1uixrilux'; 
// const IDENTITY_POOL_ID = 'us-east-1:53c59198-ae45-49d2-a52b-53fe1b86987f'; 

const s3 = new S3Client({
    region: REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    }
});

const client = generateClient();
const EditProfileModal = ({ showModal, setShowModal, userData, setUpdating }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [imageKey, setImageKey] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [userImage, setUserImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [userImageName, setUserImageName] = useState(null);
  const { orgId } = useSelector((state) => state.organization);

  const handleImageChange = async (event) => {
    console.log(event.target.files[0].name);

    let currentVersion = userData.image ? userData.image.split('/')[4] : 'v0';
    console.log("Current Version ", currentVersion)
    // currentVersion = currentVersion === 'vNaN' ? 'v0' : currentVersion;
    const newVersion = `v${parseInt(currentVersion.slice(1)) + 1}`;

    const file = event.target.files[0];
    if (!file) {
      alert('Please select a file to upload');
      return;
    }

    setUploading(true);

    const folderName = 'profileUploads';
    const fileName = userData.id.replace(/@/g, "_AT_").replace(/\./g, "_DOT_");

    setSelectedImage(URL.createObjectURL(file));

    const params = {
      Bucket: BUCKET_NAME,
      Key: `${folderName}/${newVersion}/${fileName}`,
      Body: file,
      ContentType: "image/jpeg",
      CacheControl: 'no-cache, no-store, must-revalidate',
    };

    

    try {
      const command = new PutObjectCommand(params);
      await s3.send(command);
      const uploadUrl = `${process.env.REACT_APP_IMAGE_CLOUDFRONT_DISTRIBUTION}/${folderName}/${newVersion}/${fileName}`;
      // dispatch(setProfileImage(uploadUrl))
      // setUploadSuccess(`File uploaded successfully: ${uploadUrl}`);
      setImageKey(prevKey => prevKey + 1);
      setUserImage(uploadUrl);
      setUserImageName(fileName);
    } catch (error) {
      console.error('Error uploading file: ', error);
      // setUploadError('File upload failed');
    } finally {
      // setUploading(false);
    }
  };


  console.log("User Image ", userImage, userImageName)
  return (
    <>
      {showModal ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
            <div className="relative max-h-full w-full max-w-md p-4">
              {/* Modal content */}
              <div className="relative rounded-lg bg-white shadow dark:bg-gray-700">
                {/* Modal header */}
                <div className="flex items-center justify-between rounded-t border-b p-4 dark:border-gray-600 md:p-5">
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                    Profile Details
                  </h3>
                  <button
                    type="button"
                    onClick={() => setShowModal(false)}
                    className="inline-flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-400 ms-auto end-2.5 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-hide="authentication-modal"
                  >
                    <svg
                      className="h-3 w-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                {/* Modal body */}
                <div className="p-4 md:p-5">

                  <div className="flex items-center lg:justify-between space-x-4 lg:space-x-6">
                    <div className="relative group max-w-fit mx-auto mt-6">
                      <img
                        style={{
                          borderRadius: "50%",
                        }}
                        key={imageKey}
                        src={uploading ? selectedImage : userImage || userData.image || avatar}
                        alt=""
                        width={150}
                      />

                      <div className="flex justify-center items-center w-full">
                        <label
                          htmlFor="dropzone-file"
                          className="absolute bottom-0 left-28 justify-center items-center bg-gray-50 rounded-full  cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                        >
                          <FaPencilAlt className="w-6 h-6 text-brandLinear dark:text-lightPrimary" />
                          <input
                            onChange={handleImageChange}
                            id="dropzone-file"
                            type="file"
                            className="hidden"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <Formik

                    initialValues={{
                      firstName: userData.firstName,
                      lastName: userData.lastName,
                      // email: userData.email,
                      gender: userData.gender,
                      birthDate: userData.birthDate,
                      phoneNumber: userData.phoneNumber,
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      // TODO: Update user profile call api
                      setUpdating(false);
                      setLoading(true);
                      
                      const updatedUserData = await client.graphql({
                        query: updateUserProfile,
                        variables: {
                          input: {
                            orgName: orgId,
                            email: userData.email,
                            firstName: values.firstName,
                            lastName: values.lastName,
                            gender: values.gender,
                            birthDate: values.birthDate,
                            phoneNumber: values.phoneNumber,
                            image: userImage || userData.image,
                          },
                        },
                      });
                      console.log(updatedUserData);


                      setLoading(false);
                      setUserImage(null)
                      setUserImageName(null)
                      setSubmitting(false);
                      setShowModal(false);
                      dispatch(setProfileImage(userImage || userData.image));
                      setUpdating(true);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                    }) => (
                      <form className="space-y-4 text-gray-900 dark:text-white" onSubmit={handleSubmit}>

                        <TextField
                          name="firstName"
                          label="First Name"
                          onChange={handleChange}
                          value={values.firstName}
                          extra="w-full text-gray-900 dark:text-white"
                        />
                        <TextField
                          name="lastName"
                          label="Last Name"
                          onChange={handleChange}
                          value={values.lastName}
                          extra="w-full"
                        />
                        <TextField
                          label="Gender"
                          name="gender"
                          onChange={handleChange}
                          value={values.gender}
                          extra="w-full"
                        />
                        <TextField
                          label="Date of Birth"
                          name="birthDate"
                          onChange={handleChange}
                          value={values.birthDate}
                          extra="w-full"
                        />
                        <TextField
                          label="Phone Number"
                          name="phoneNumber"
                          onChange={handleChange}
                          value={values.phoneNumber}
                          extra="w-full"
                        />
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className={` ${isSubmitting && "disabled"} w-full rounded-md bg-brand-900 px-5 py-2.5 text-base font-medium text-white text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90`}
                        >
                          {isSubmitting ? "Updating..." : "Update"}
                        </button>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      ) : null}
    </>
  );
};

export default EditProfileModal;
