import { useSelector } from "react-redux";
import { generateClient } from "aws-amplify/api";
import { useState, useEffect } from "react";
import { getAssessment } from "graphql/queries";
import { getUserProfile } from "graphql/queries";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Bars } from 'react-loading-icons';
import { fetchUserAttributes } from "aws-amplify/auth";

const client = generateClient();

const AssessmentResults = () => {

  const [showBars, setShowBars] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowBars(false); // Hide the Bars component after 3 seconds
    }, 2000);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  const { user } = useAuthenticator();

  // const [userData, setUserData] = useState();
  const [result, setResult] = useState();
  const [resultArray, setResultArray] = useState();
  // const[isAssessmentCompleted, setIsAssessmentCompleted] = useState(false);
  const [attemptedQuestions, setAttemptedQuestions] = useState();
  const [totalQuestions, setTotalQuestions] = useState();
  const [resultState, setResultState] = useState();
  const [isAssessmentCompleted, setIsAssessmentCompleted] = useState(false);
  const assessmentKey = useSelector((state) => {
    return state.assessmentKey;
  });
  const { orgId} = useSelector((state) => state.organization); 

  useEffect(() => {
    const fetchUserDetails = async () => {
      const { email } = await fetchUserAttributes();
      const userProfile = await client.graphql({
        query: getUserProfile,
        variables: {
          input: {
            email: email,
            orgName: orgId,
          },
        },
      });
      if (userProfile.data.getUserProfile) {
        // setUserData(userProfile.data.getUserProfile.questionData);
        // TODO: here modify the path 
        if (userProfile.data.getUserProfile.questionData) {
          const { questionData } = userProfile.data.getUserProfile;
          for (let i = 0; i < questionData.length; i++) {
            if (questionData[i].key === assessmentKey) {
              // console.log("hello world inside");
              const {
                questionNum,
                questions,
                totals,
                chosenValues,
                results,
                result,
              } = questionData[i];
              console.log("RESULT werewjlr ", result);
              setResult(result);
              setAttemptedQuestions(questionNum);
            }
          }
        }
      }
      if (attemptedQuestions === totalQuestions) {
        setIsAssessmentCompleted(true);
      }
    };
    fetchUserDetails();
  }, [user]);

  useEffect(() => {
    const fetchAssessment = async () => {
      const assessmentQuestions = await client.graphql({
        query: getAssessment,
        variables: {
          input: {
            assessmentKey: assessmentKey,
          },
        },
      });
      console.log(assessmentQuestions.data.getAssessment.results);
      setResultArray(assessmentQuestions.data.getAssessment.results);
      setTotalQuestions(assessmentQuestions.data.getAssessment.questions.length)
    };
    fetchAssessment();
  }, []);

  useEffect(() => {

    let state = [];

    if (result && resultArray) {
      let resultStringArray = result.split(",")
      console.log("RESULT STRING ARRAY ", resultStringArray)
      for (let i = 0; i < resultStringArray.length; i++) {
        for (let j = 0; j < resultArray.length; j++) {
          if (resultStringArray[i].trim() === resultArray[j]["key"].trim()) {
            state.push(resultArray[j]);
          }
        }
        
      }
    }
    setResultState(state);
  }, [result, resultArray, isAssessmentCompleted])


  

  return (
    <div>
      {showBars ? <Bars className="text-center h-20 mt-5 w-full items-center" fill="#D0CECF" /> : isAssessmentCompleted
        ?
        <>
          <div className="mt-5">
            <h2 className="text-lg font-bold text-navy-700 dark:text-white">
              {assessmentKey}
            </h2>
            <div className="mt-3 h-full w-full bg-white hover:bg-gray-200 dark:!bg-navy-800 dark:hover:!bg-navy-700 h-72 dark:text-white text-center text-bold text-lg pt-5">
              Personal Graph
            </div>
          </div>
          <div className="flex flex-row mt-3 justify-between flex-wrap scoredResult">
            {console.log("RESULT STATE ", resultState)}
            {resultState.map((badgeObject) => {
              return (
                <div className="w-1/2 mt-3 eachBadge space-y-2">
                  <h2 className="text-lg font-bold text-navy-700 dark:text-white">
                    Scored Result
                  </h2>
                  <div className="p-4 bg-white hover:bg-gray-200 dark:!bg-navy-800 dark:hover:!bg-navy-700 min-h-72 dark:text-white pl-3 pt-2 align-middle">
                    <div className="flex flex-row items-center">
                      <img className="h-16 dark:bg-brand-400 p-1 rounded" src={"https://s3.amazonaws.com/iConverge/" + badgeObject.image} alt={badgeObject.key} />
                      <h3 className="text-lg font-bold ml-3">{badgeObject.key}: </h3>
                    </div>

                    <p className="mt-3">{badgeObject.description}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </>
        :
        <h5 className="font-bold text-lg text-center dark:text-white mt-10">Please Complete the Assessment</h5>}
      {/* <div className="mt-5">
                <h2 className="text-lg font-bold text-navy-700 dark:text-white">
                    {assessmentKey}
                </h2>
                <div className="mt-3 h-full w-full bg-white hover:bg-gray-200 dark:!bg-navy-800 dark:hover:!bg-navy-700 h-72 text-white text-center items-center">
                    Personal Graph
                </div>
            </div>
            <div className="flex flex-row mt-3 justify-between flex-wrap scoredResult">

                {state.map((badgeObject) => {
                  return (
                    <div className="w-1/2 mt-3">
                      <h2 className="text-lg font-bold text-navy-700 dark:text-white">
                          Scored Result
                      </h2>
                      <div className="bg-white hover:bg-gray-200 dark:!bg-navy-800 dark:hover:!bg-navy-700 h-72 mt-2 dark:text-white pl-3 pt-2 align-middle">
                          <div className="flex flex-row items-center">
                            <img className="h-16 dark:bg-brand-400 p-1 rounded" src={"https://s3.amazonaws.com/iConverge/" + badgeObject.image} />
                            <h3 className="text-lg font-bold ml-3">{badgeObject.key}: </h3>
                          </div>
                          

                          <p className="mt-3">{badgeObject.description}</p>
                      </div>
                    </div>
                  )
                })}
            </div>  */}
    </div>
  )
}

export default AssessmentResults;