import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import routes from "routes.js";
import { useSelector } from "react-redux";

export default function Dashboard(props) {
  const { ...rest } = props;
  const location = useLocation();
  const { routes: accessibleRoutes } = useSelector((state) => state.organization);
  const [open, setOpen] = React.useState(window.innerWidth >= 1200);
  const [currentRoute, setCurrentRoute] = React.useState("Personal Profile");

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
    return () => {
      window.removeEventListener("resize", () =>
        window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
      );
    };
  }, []);

  React.useEffect(() => {
    setCurrentRoute(getActiveRoute(routes));
  }, [location.pathname, routes]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Personal Profile";
    const currentPath = location.pathname.split("/").pop();
    for (let i = 0; i < routes.length; i++) {
      if (currentPath === routes[i].path) {
        activeRoute = routes[i].name;
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].secondary;
      }
    }
    return false;
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/") {
        const accessible = accessibleRoutes.some((route) => route.path === prop.path);
        if (accessible) {
          return <Route path={`/${prop.path}`} element={prop.component} key={key} />;
        }
      }
      // Only return null here; our wildcard route will catch unmatched paths
      return null;
    });
  };

  document.documentElement.dir = "ltr";

  return (
    <div className="flex h-full w-full xl:space-x-4">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        {/* Main Content */}
        <main className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}>
          {/* Routes */}
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              logoText={"GlobalEquipping"}
              brandText={currentRoute}
              secondary={getActiveNavbar(routes)}
              {...rest}
            />
            <div className="pt-5 mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
              <Routes>
                {getRoutes(routes)}

                {/* Redirect any unmatched routes to the profile and update the URL */}
                <Route path="*" element={<Navigate to="/profile" replace />} />
              </Routes>
            </div>
            <div className="p-3">
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
