import React, { ReactNode } from "react";
import {Amplify} from "aws-amplify";
import awsmobile from "../aws-exports";

export const AmplifyClient = ({ children }) => {
  // check whether its local or fetch the redirect url from window.location.origin
  if (process.env.NODE_ENV !== "development") {
    awsmobile.oauth.redirectSignIn = window.location.origin;
    awsmobile.oauth.redirectSignOut = window.location.origin;
  }
  Amplify.configure(awsmobile);

  return <div>{children}</div>;
};
