import RadioOption from "./RadioOption";
import { useState, useEffect } from 'react';

function QuizOptionBox({ selectedOption, answers, handleSelect }) {

    // function activeRadio(radio_value, label){

    //     let parts = radio_value.slice(1, -1).split('=');

    //     let key = parts[0];
    //     let value = parseInt(parts[1], 10); 

    //     setSelectedOption(radio_value);

    //     props.trackRadio(key, value, label);
    // }



    return (
        <>
            {answers.map((choice, i) => {
                return (
                    <RadioOption
                        key={i+1}
                        correct={i == selectedOption}
                        onClick={() =>
                            handleSelect(choice.value.split("=")[0].slice(1), parseInt(choice.value.split("=")[1].slice(0,-1)), i)
                        }
                        color="navy"
                    >
                        {choice.label}
                    </RadioOption>
                );
            })}
        </>
    );
}

export default QuizOptionBox;
