import avatar from "assets/img/avatars/avatarUser.jpg";
import EditProfileModal from "./EditProfileModal";
import { useState, useEffect } from "react";

const ProfileCard = ({ userData, setUpdating }) => {
  const [showModal, setShowModal] = useState(false);
  const [imageBlobUrl, setImageBlobUrl] = useState('');

    const fetchImage = async () => {
        try {
            setImageBlobUrl(userData.image);
        } catch (error) {
            console.error('Error fetching image:', error);
        }
    };

    useEffect(() => {
        fetchImage(); 
    }, [userData]);

  return (
    <>
      <div className="flex h-full w-full items-start justify-between bg-white px-3 py-[20px] dark:!bg-navy-800 dark:shadow-none dark:hover:!bg-navy-700">
        <div className="flex items-center gap-3">
          <div className="flex h-16 w-16 items-center justify-center">
            <img
              className="h-full w-full rounded-xl"
              src={userData.image !== "" ? imageBlobUrl : avatar}
              alt=""
            />
          </div>
          <div className="flex flex-col">
            <h5 className="text-lg font-bold text-navy-700 dark:text-white">
              {" "}
              {userData.firstName} {userData.lastName}
            </h5>
            <p className="mt-1 text-sm font-semibold text-gray-400">
              {" "}
              Email: {userData.email}
            </p>
            <p className="mt-1 text-sm font-semibold text-gray-400">
              {" "}
              Phone: {userData.phoneNumber}
            </p>
          </div>
        </div>
        <div className="my-auto flex items-center justify-center space-x-8 px-4 text-navy-700 dark:text-white">
          <button
            onClick={() => setShowModal(true)}
            className="linear rounded-[20px] bg-brand-900 px-8 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
          >
            Edit
          </button>
        </div>
      </div>

      <EditProfileModal
        userData={userData}
        showModal={showModal}
        setShowModal={setShowModal}
        setUpdating={setUpdating}
      />
    </>
  );
};

export default ProfileCard;
