import React from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link } from "react-router-dom";
import navbarimage from "assets/img/layout/Navbar.png";
import { BsArrowBarUp } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import {
  IoMdNotificationsOutline,
  IoMdInformationCircleOutline,
} from "react-icons/io";
import avatar from "assets/img/avatars/avatarUser.jpg";
import { useEffect, useState } from "react";
import { generateClient } from "aws-amplify/api";
import { getUserProfile } from "graphql/queries";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { fetchUserAttributes } from "aws-amplify/auth";
import { useSelector } from "react-redux";

const client = generateClient();

const Navbar = (props) => {
  const { onOpenSidenav, brandText } = props;
  const { user, signOut } = useAuthenticator();
  const [userData, setUserData] = React.useState({});
  const [darkmode, setDarkmode] = React.useState(true);
  const [imageBlobUrl, setImageBlobUrl] = useState('');
  const { orgId } = useSelector((state) => state.organization);
  const { userId, profileImage } = useSelector((state) => state.user);

  useEffect(() => {
    document.body.classList.add("dark");
    console.log("USER frpm navbar", userId)
    console.log("USER from navbar", profileImage)
  }, [user]);

  return (
    <nav className="top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl bg-navy-900 dark:bg-white"> {/* dark:bg-[#0b14374d] */}
      <div className="ml-[6px]">
        {/* <div className="h-6 w-[224px] pt-1">
          <a
            className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            href=" "
          >
            Pages
            <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
              {" "}
              /{" "}
            </span>
          </a>
          <Link
            className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to="#"
          >
            {brandText}
          </Link>
        </div> */}
        <p className="shrink text-[33px] capitalize text-white dark:text-black font-bold capitalize"> {/** dark:text-white text-navy-700*/}
          {/* <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          > */}
            {brandText}
          {/* </Link> */}
        </p>
      </div>

      <div className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-navy-800 dark:bg-white px-2 py-2 shadow-xl shadow-shadow-500 md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2"> {/** dark:!bg-navy-800 dark:shadow-none */}
        <div className="flex h-full items-center rounded-full bg-navy-900 text-white dark:bg-lightPrimary dark:text-navy-700 xl:w-[225px]"> {/**dark:bg-navy-900 dark:text-white*/}
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-4 w-4 text-white dark:text-gray-400" /> {/**dark:text-white */}
          </p>
          <input
            type="text"
            placeholder="Search..."
            class="block h-full w-full rounded-full bg-navy-900 text-white placeholder:!text-white dark:bg-lightPrimary text-sm font-medium dark:text-navy-700 outline-none dark:placeholder:!text-gray-400 sm:w-fit" /**dark:bg-navy-900 dark:text-white dark:placeholder:!text-white */
          />
        </div>
        <span
          className="flex cursor-pointer text-xl text-white dark:text-gray-600 xl:hidden" /**dark:text-white */
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>
        
        <div
        
          className="cursor-pointer text-gray-600"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove("dark");
              setDarkmode(false);
            } else {
              document.body.classList.add("dark");
              setDarkmode(true);
            }
          }}
        >
          {darkmode ? (
            <RiSunFill className="h-4 w-4 text-gray-600" /> // dark:text-white
          ) : (
            <RiMoonFill className="h-4 w-4 text-gray-600" /> // dark:text-white
          )}
        </div>
        {/* Profile & Dropdown */}
        <Dropdown
          button={
            <img
              className="h-10 w-10 rounded-full"
              src={profileImage || avatar}
              alt={userId}
            />
          }
          children={
            <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="p-4">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    👋 Hey, {userId}
                  </p>{" "}
                </div>
              </div>
              <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="flex flex-col p-4">
                <a
                  href=" "
                  className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Profile Settings
                </a>
                <a
                  href=" "
                  className="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Newsletter Settings
                </a>
                <a
                  href=" "
                  className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                  onClick={signOut}
                >
                  Log Out
                </a>
              </div>
            </div>
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />
      </div>
    </nav>
  );
};

export default Navbar;
