

 export const formFields = {
    signUp: {
      given_name: {
        labelHidden: true,
        isRequired: true,
        placeHolder: "Enter First Name",
        order: 1
      },
      family_name: {
        labelHidden: true,
        isRequired: true,
        placeHolder: "Enter Last Name",
        order: 2
      },
      email: {
        isRequired: true,
        labelHidden: false,
        order: 4
      },
      gender: {
        placeHolder: "Gender",
        order: 3
      },
      birthdate: {
        isRequired: true,
        order: 5
      },
      phone_number: {
        isRequired: true,
        order: 6
      },
      password: {
        isRequired: true,
        order: 7
      },
      confirm_password: {
        isRequired: true,
        order: 8
      }
    }
  }