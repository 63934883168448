import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const CourseForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formik = useFormik({
    initialValues: {
      courseTitle: '',
      courseName: '',
      youtubeLink: '',
      courseLevel: '',
      organization: '',
      name: '',
      sections: [],
      contentPages: '',
      quizQuestions: '',
    },
    validationSchema: Yup.object({
      courseTitle: Yup.string().required('Required'),
      courseName: Yup.string().required('Required'),
      youtubeLink: Yup.string().url('Invalid URL').required('Required'),
      courseLevel: Yup.string().required('Required'),
      organization: Yup.string().required('Required'),
      name: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        // Simulate form submission
        await new Promise((resolve) => setTimeout(resolve, 2000));
        console.log('Form Values:', JSON.stringify(values, null, 2));
      } catch (error) {
        console.error('Submission Error:', error);
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  return (
    <div className="flex justify-center items-center h-full p-5">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full overflow-auto">
        <h1 className="text-2xl mb-6 text-center text-gray-800">Transcribe YouTube Video</h1>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-4">
            <label htmlFor="courseTitle" className="block text-gray-700 font-semibold mb-2">Course Title</label>
            <input
              id="courseTitle"
              name="courseTitle"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.courseTitle}
              className={`w-full p-3 border rounded-lg ${formik.touched.courseTitle && formik.errors.courseTitle ? 'border-red-500' : 'border-gray-300'}`}
            />
            {formik.touched.courseTitle && formik.errors.courseTitle ? <div className="text-red-500 text-sm mt-1">{formik.errors.courseTitle}</div> : null}
          </div>
          <div>
          <label htmlFor="courseName" className="block text-gray-700 font-semibold mb-2">Course Name</label>
            <input
              id="courseName"
              name="courseName"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.courseName}
              className={`w-full p-3 border rounded-lg ${formik.touched.courseName && formik.errors.courseName ? 'border-red-500' : 'border-gray-300'}`}
            />
            {formik.touched.courseName && formik.errors.courseName ? <div className="text-red-500 text-sm mt-1">{formik.errors.courseName}</div> : null}
          </div>

          <div className="mb-4">
            <label htmlFor="youtubeLink" className="block text-gray-700 font-semibold mb-2">YouTube Link</label>
            <input
              id="youtubeLink"
              name="youtubeLink"
              type="url"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.youtubeLink}
              className={`w-full p-3 border rounded-lg ${formik.touched.youtubeLink && formik.errors.youtubeLink ? 'border-red-500' : 'border-gray-300'}`}
            />
            {formik.touched.youtubeLink && formik.errors.youtubeLink ? <div className="text-red-500 text-sm mt-1">{formik.errors.youtubeLink}</div> : null}
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2">Course Level</label>
            <div className="flex justify-around">
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="courseLevel"
                  value="Beginner"
                  onChange={formik.handleChange}
                  className="form-radio text-purple-600"
                />
                <span className="ml-2">Beginner</span>
              </label>

              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="courseLevel"
                  value="Intermediate"
                  onChange={formik.handleChange}
                  className="form-radio text-purple-600"
                />
                <span className="ml-2">Intermediate</span>
              </label>

              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="courseLevel"
                  value="Advanced"
                  onChange={formik.handleChange}
                  className="form-radio text-purple-600"
                />
                <span className="ml-2">Advanced</span>
              </label>
            </div>
          </div>

          <div className="mb-4">
            <label htmlFor="organization" className="block text-gray-700 font-semibold mb-2">Organization</label>
            <input
              id="organization"
              name="organization"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.organization}
              className={`w-full p-3 border rounded-lg ${formik.touched.organization && formik.errors.organization ? 'border-red-500' : 'border-gray-300'}`}
            />
            {formik.touched.organization && formik.errors.organization ? <div className="text-red-500 text-sm mt-1">{formik.errors.organization}</div> : null}
          </div>

          <div className="mb-4">
            <label htmlFor="name" className="block text-gray-700 font-semibold mb-2">Name</label>
            <input
              id="name"
              name="name"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              className={`w-full p-3 border rounded-lg ${formik.touched.name && formik.errors.name ? 'border-red-500' : 'border-gray-300'}`}
            />
            {formik.touched.name && formik.errors.name ? <div className="text-red-500 text-sm mt-1">{formik.errors.name}</div> : null}
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2">Include Sections:</label>
            <div className="flex flex-col">
              <div className="flex items-center mb-2">
                <input
                  type="checkbox"
                  id="syllabus"
                  name="sections"
                  value="Syllabus"
                  onChange={formik.handleChange}
                  className="form-checkbox text-purple-600"
                />
                <label htmlFor="syllabus" className="ml-2">Syllabus</label>
              </div>

              <div className="flex items-center mb-2">
                <input
                  type="checkbox"
                  id="courseIntro"
                  name="sections"
                  value="Course Intro"
                  onChange={formik.handleChange}
                  className="form-checkbox text-purple-600"
                />
                <label htmlFor="courseIntro" className="ml-2">Course Intro</label>
              </div>

              <div className="flex items-center mb-2">
                <input
                  type="checkbox"
                  id="content"
                  name="sections"
                  value="Content"
                  onChange={formik.handleChange}
                  className="form-checkbox text-purple-600"
                />
                <label htmlFor="content" className="ml-2">Content</label>
                {formik.values.sections.includes('Content') && (
                  <select
                    id="contentPages"
                    name="contentPages"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.contentPages}
                    className="ml-2 border rounded-lg p-2"
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                )}
              </div>

              <div className="flex items-center mb-2">
                <input
                  type="checkbox"
                  id="quiz"
                  name="sections"
                  value="Quiz"
                  onChange={formik.handleChange}
                  className="form-checkbox text-purple-600"
                />
                <label htmlFor="quiz" className="ml-2">Quiz</label>
                {formik.values.sections.includes('Quiz') && (
                  <select
                    id="quizQuestions"
                    name="quizQuestions"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.quizQuestions}
                    className="ml-2 border rounded-lg p-2"
                  >
                    <option value="3">3</option>
                    <option value="5">5</option>
                    <option value="10">10</option>
                  </select>
                )}
              </div>
            </div>
          </div>

          <div className="text-red-500 text-sm text-center mb-4">{formik.errors.general}</div>

          <button
            type="submit"
            className={`w-full p-3 text-white font-semibold rounded-lg ${
              isSubmitting ? 'bg-gray-600 cursor-not-allowed' : 'bg-purple-600 hover:bg-purple-700'
            }`}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CourseForm;
