import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import '@aws-amplify/ui-react/styles.css';
import { Provider } from "react-redux";
import { store } from "./store";

import App from "./App";
import { AmplifyClient } from "./utils/amplifyClient";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <AmplifyClient>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </AmplifyClient>
);
