import { FaChevronDown } from "react-icons/fa";
import { useState, useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { getUserProfile } from "graphql/queries";
import { generateClient } from "aws-amplify/api";
import { getOrganization } from "graphql/queries";
import logo from "assets/img/RHLClogo.jpg";
import { Bars } from "react-loading-icons";
import { BsPlusLg } from "react-icons/bs";
import { LuPencilLine } from "react-icons/lu";
import { MdDelete } from "react-icons/md";
// import dispatch and dispatch action to set orgId

import { useSelector, useDispatch } from "react-redux";
import EditOrganizationModal from "./components/EditOrganizationModal";
import { setOrgId } from "store";
import { setOrgName } from "store";
import { setAdminEmails } from "store";
import { fetchUserAttributes } from "aws-amplify/auth";

const client = generateClient();

const Organization = () => {
  const dispatch = useDispatch();
  const orgId = useSelector((state) => state.organization.orgId);

  const [userData, setUserData] = useState({});

  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false);

  const { user } = useAuthenticator();
  const [updating, setUpdating] = useState(false);

  const [orgData, setOrgData] = useState();
  const [showModal, setShowModal] = useState(false);

  const { orgId: selectedOrg } = useSelector((state) => state.organization);

  const [isFetching, setIsFetching] = useState(false);

  // get orgId from store

  useEffect(() => {
    const fetchUserDetails = async () => {
      const { email } = await fetchUserAttributes();
      const userProfile = await client.graphql({
        query: getUserProfile,
        variables: {
          input: {
            email: email,
            orgName: selectedOrg,
          },
        },
      });
      if (userProfile.data.getUserProfile)
        setUserData(userProfile.data.getUserProfile);
    };
    fetchUserDetails();
  }, [user, updating]);

  const fetchOrganization = async (orgName) => {
    const organization = await client.graphql({
      query: getOrganization,
      variables: {
        input: {
          name: orgName,
        },
      },
    });
    if (organization) {
      setOrgData(organization.data.getOrganization);
      dispatch(setOrgId(organization.data.getOrganization.id));
      dispatch(setOrgName(organization.data.getOrganization.name));
      dispatch(setAdminEmails(organization.data.getOrganization.adminEmails));
      // check if user is admin
      const { email } = await fetchUserAttributes();
      if (
        organization.data.getOrganization.adminEmails.includes(email)
      ) {
        setIsAdminLoggedIn(true);
      }

      setIsFetching(false);
    }
  };

  useEffect(() => {
    setIsFetching(true);
    fetchOrganization(orgId);
  }, []);

  useEffect(() => {
    if (selectedOrg || orgData) {
      setIsFetching(true);
      fetchOrganization(selectedOrg || orgData.id);
    }
  }, [updating]);

  console.log("USER DATA => ", userData);
  console.log("ORGANIZATION DATA => ", orgData);

  // const dropdownOptions = ["rhlc.us", "globalequipping.com"];

  // // JavaScript to toggle dropdown visibility
  // function toggleDropdown() {
  //   var dropdownContent = document.getElementById("dropdownContent");
  //   dropdownContent.classList.toggle("hidden");
  // }

  // function reFetchOrg(orgName) {
  //   setSelectedOrg(orgName);
  //   setIsFetching(true);
  //   fetchOrganization(orgName);
  //   toggleDropdown();
  // }

  return (
    <>
      <div>
        <div className="mt-5 flex flex-row items-center justify-between">
          <h2 className="text-lg font-bold text-navy-700 dark:text-white">
           YOUR ORGANIZATION AT A GLANCE
          </h2>

          {/* <div className="dropdown">
            <button
              id="dropdownButton"
              className="linear mr-2 flex w-52 items-center justify-center rounded-[20px] border bg-brand-900 px-5 py-1 text-base font-medium text-white transition duration-200 hover:bg-brand-800 dark:bg-transparent dark:text-white dark:hover:bg-navy-700"
              onClick={toggleDropdown}
            >
              {selectedOrg ? selectedOrg : orgData ? orgData.id : "Select Org"}{" "}
              <FaChevronDown class="ml-1" />
            </button>
            <div
              id="dropdownContent"
              className="dropdown-content mt-10 hidden justify-center"
            >
              {dropdownOptions.map((option) => {
                return (
                  <div
                    onClick={() => reFetchOrg(option)}
                    className="dropdown-item linear mr-2 flex items-center border bg-brand-900 px-5 py-1 text-base font-medium text-white transition duration-200 hover:bg-brand-800 dark:bg-transparent dark:text-white dark:hover:bg-navy-700"
                  >
                    {option}
                  </div>
                );
              })}
            </div>
          </div> */}
        </div>

        {isFetching ? (
          <Bars className="mt-4 mb-4 h-20 w-full items-center" fill="#D0CECF" />
        ) : (
          <div className="mt-4 flex flex h-fit flex-row flex-row flex-wrap items-start items-center justify-between bg-white dark:bg-navy-800 dark:shadow-none dark:hover:!bg-navy-700">
            {/* Image, Name, URL etc */}

            <div className="flex h-full flex-row items-center">
              <div className="p-3">
                <img src={orgData && orgData.logoImage || logo} alt="Logo" width="100" />
              </div>
              <div className="flex flex-col">
                <div className="flex flex-col p-4">
                  <h5 className="text-lg font-bold text-navy-700 dark:text-white">
                    {" "}
                    {orgData && orgData.name}
                  </h5>
                  <p className="mt-1 flex flex-row text-lg font-bold text-gray-400">
                    {" "}
                    Admin Emails:
                    {orgData && orgData.adminEmails.map((email) => (
                      <span className="ml-2 font-semibold dark:text-white">
                        {email}
                      </span>
                    ))}
                    
                  </p>
                  <p className="mt-1 text-sm font-semibold text-gray-400">
                    {" "}
                    URL: {selectedOrg}
                  </p>
                  <p className="mt-1 text-sm font-semibold text-gray-400">
                    {" "}
                    Email: {orgData && orgData.email}
                  </p>
                  <p className="mt-1 text-sm font-semibold text-gray-400">
                    {" "}
                    Phone: {orgData && orgData.phone}
                  </p>
                </div>
              </div>
            </div>
            {isAdminLoggedIn ? (
              <div className="mr-4 flex flex-row flex-wrap">
                <button
                  onClick={() => setShowModal(true)}
                  className="linear w-28 rounded-[20px] bg-brand-900 px-8 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                >
                  Edit
                </button>
                {/* <button className="linear ml-2 w-28 rounded-[20px] bg-brand-900 px-8 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 dark:bg-white dark:text-brand-900 dark:hover:bg-gray-200">
                Save
              </button> */}
              </div>
            ) : (
              ""
            )}
          </div>
        )}

        <div className="mt-4 flex h-28 flex-row items-start space-x-8 bg-white p-4 dark:bg-navy-800 dark:shadow-none dark:hover:!bg-navy-700">
          <div className="w-full">
            <p className="text-black dark:text-white">Organization Type</p>
            <div className="linear mr-2 mt-2 flex h-10 w-full items-center border border-black px-3 py-1 text-base font-medium text-black transition duration-200 hover:bg-gray-300 dark:border-white dark:bg-transparent dark:text-white dark:hover:bg-navy-700">
              <span className="flex w-full items-center justify-between">
                <span>{orgData && orgData.organizationType}</span>
              </span>
            </div>
          </div>

          <div className="w-full">
            <p className="text-black dark:text-white">Category</p>
            <div className="linear mr-2 mt-2 flex h-10 w-full items-center border border-black px-3 py-1 text-base font-medium text-black transition duration-200 hover:bg-gray-300 dark:border-white dark:bg-transparent dark:text-white dark:hover:bg-navy-700">
              <span className="flex w-full items-center justify-between">
                <span>{orgData && orgData.category}</span>
              </span>
            </div>
          </div>
        </div>

        <h5 className="mt-4 font-normal text-navy-700 dark:text-white">
          Address
        </h5>

        <div className="mt-2 flex flex flex-col flex-wrap items-start bg-white p-4 dark:bg-navy-800 dark:shadow-none dark:hover:!bg-navy-700">
          {isFetching ? (
            <Bars
              className="mt-4 mb-4 h-20 w-full items-center"
              fill="#D0CECF"
            />
          ) : (
            <>
              <div className="mb-1 flex w-full flex-row justify-between space-x-8">
                <div className="w-full">
                  <p className="text-black dark:text-white">Street</p>
                  <div className="linear mr-2 mt-2 flex h-10 w-full items-center border border-black px-3 py-1 text-base font-medium text-black transition duration-200 hover:bg-gray-300 dark:border-white dark:bg-transparent dark:text-white dark:hover:bg-navy-700">
                    <span className="flex w-full items-center justify-between">
                      <span>{orgData && orgData.address.street}</span>
                    </span>
                  </div>
                </div>

                <div className="w-full">
                  <p className="text-black dark:text-white">City</p>
                  <div className="linear mr-2 mt-2 flex h-10 w-full items-center border border-black px-3 py-1 text-base font-medium text-black transition duration-200 hover:bg-gray-300 dark:border-white dark:bg-transparent dark:text-white dark:hover:bg-navy-700">
                    <span className="flex w-full items-center justify-between">
                      <span>{orgData && orgData.address.city}</span>
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-1 flex w-full flex-row justify-between space-x-8">
                <div className="w-full">
                  <p className="text-black dark:text-white">State</p>
                  <div className="linear mr-2 mt-2 flex h-10 w-full items-center border border-black px-3 py-1 text-base font-medium text-black transition duration-200 hover:bg-gray-300 dark:border-white dark:bg-transparent dark:text-white dark:hover:bg-navy-700">
                    <span className="flex w-full items-center justify-between">
                      <span>{orgData && orgData.address.state}</span>
                    </span>
                  </div>
                </div>

                <div className="w-full">
                  <p className="text-black dark:text-white">Zip</p>
                  <div className="linear mr-2 mt-2 flex h-10 w-full items-center border border-black px-3 py-1 text-base font-medium text-black transition duration-200 hover:bg-gray-300 dark:border-white dark:bg-transparent dark:text-white dark:hover:bg-navy-700">
                    <span className="flex w-full items-center justify-between">
                      <span>{orgData && orgData.address.zip}</span>
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {/* {isAdminLoggedIn ? (
        <div className="mt-8 flex flex-row flex-wrap items-center justify-center space-x-4 text-center">
          <button className="linear w-28 rounded-[20px] bg-brand-900 px-8 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90">
            <span className="flex w-full items-center justify-center">
              <span className="mr-1">
                <BsPlusLg />
              </span>
              <span>Create</span>
            </span>
          </button>

          <button className="linear w-28 rounded-[20px] bg-brand-900 px-8 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 dark:bg-white dark:text-brand-900 dark:hover:bg-gray-200">
            <span className="flex w-full items-center justify-center">
              <span className="mr-1">
                <LuPencilLine />
              </span>
              <span>Edit</span>
            </span>
          </button>

          <button className="linear w-28 rounded-[20px] bg-brand-900 px-8 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 dark:bg-white dark:text-brand-900 dark:hover:bg-gray-200">
            <span className="flex w-full items-center justify-center">
              <span className="mr-1">
                <MdDelete />
              </span>
              <span>Delete</span>
            </span>
          </button>
        </div>
      ) : (
        ""
      )} */}
      </div>

      <EditOrganizationModal
        showModal={showModal}
        setShowModal={setShowModal}
        orgData={orgData}
        setUpdating={setUpdating}
      />
    </>
  );
};

export default Organization;
