import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    userId: "",
    profileImage: "",
  },
  reducers: {
    setUserId(state, action) {
      state.userId = action.payload;
    },
    setProfileImage(state, action) {
      state.profileImage = action.payload;
    }
  },
});

export const { setUserId, setProfileImage } = userSlice.actions;
export const userReducer = userSlice.reducer;
