import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  Authenticator,
  AmplifyProvider,
  Theme,
  useTheme,
  View,
  Image,
  Text,
  Heading,
  useAuthenticator,
  Button,
} from "@aws-amplify/ui-react";
import logo from "./images/logo.webp";
import RtlLayout from "layouts/rtl";
import DashboardLayout from "layouts/dashboard";
import AuthLayout from "layouts/auth";

import { formFields } from "./utils/constants";

// import dispatch and dispatch action to set orgId
import { useDispatch } from "react-redux";
import { setAdminEmails, setEcourses, setOrgId, setOrgName, setRoutes } from "./store";
import { setOrgBannerImage } from "store/slices/organizationSlice";
import { getOrganization } from "graphql/queries";
import { generateClient } from "aws-amplify/api";

const components = {


  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Welcome Back!
        </Heading>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <div className="text-center">
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
          >
            Reset Password
          </Button>
        </div>
      );
    },
  },

  SignUp: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Create a new account
        </Heading>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <div className="text-center">
          <Button
            fontWeight="normal"
            onClick={toSignIn}
            size="small"
            variation="link"
          >
            Back to Sign In
          </Button>
        </div>
      );
    },
  },
};

const client = generateClient();

const App = () => {
  const dispatch = useDispatch();
  const [organizationName, setOrganizationName] = useState("");
  const [bannerImage, setBannerImage] = useState("");

  useEffect(() => {
    console.log("Hello from useeffect")
    console.log("api key= > ", process.env.REACT_APP_API_KEY)
    const hostname = window.location.hostname;

    const mainDomain = "globalequipping.com";
    const subdomain = hostname.replace(`.${mainDomain}`, '')
    // setOrgName(process.env.REACT_APP_ORG_NAME || subdomain)

    dispatch(setOrgId(process.env.REACT_APP_ORG_NAME || subdomain));

    const fetchOrganization = async (orgName) => {
      const organization = await client.graphql({
        query: getOrganization,
        variables: {
          input: {
            name: orgName,
          },
        },
        authMode: "apiKey",
        authToken: process.env.REACT_APP_API_KEY,
      });

      console.log("api key= > ", process.env.REACT_APP_API_KEY)

      console.log(organization)
      if (organization) {
        // dispatch(setOrgId(organization.data.getOrganization.id));
        dispatch(setOrgName(organization.data.getOrganization.name))
        dispatch(setOrgBannerImage(organization.data.getOrganization.titleImage));
        dispatch(setAdminEmails(organization.data.getOrganization.adminEmails));
        dispatch(setRoutes(organization.data.getOrganization.routes))
        dispatch(setEcourses(organization.data.getOrganization.assessments));
        
        setBannerImage(organization.data.getOrganization.titleImage);
        setOrganizationName(organization.data.getOrganization.name);
      }
    };

    fetchOrganization(process.env.REACT_APP_ORG_NAME || subdomain);
  }, [])
  return (
    <>
        <Helmet>
          <title>{organizationName}</title>
          <link rel="icon" href={bannerImage} />
        </Helmet>
        <Authenticator
          // signUpAttributes={["given_name", "family_name"]}
          socialProviders={["facebook", "google"]}
          className="grid place-items-center"
          components={{
            ...components,
            Header() {
              const { tokens } = useTheme();

              return (
                <div className="flex flex-wrap justify-center space-x-4 pb-10 pt-2 mt-4">
                  <img alt="Amplify logo" src={bannerImage} className="w-30 h-20" />
                  {/* <p
                color={`${tokens.colors.neutral["80"]}`}
                className="my-auto text-3xl font-semibold"
              >
                {orgName.split(".")[0].toUpperCase()}
              </p> */}
                </div>
              );
            },
          }}
          loginMechanisms={["email"]}
          formFields={formFields}
        >
          <Routes>
            {console.log("oRG NAME, ", process.env.REACT_APP_ORG_NAME)}
            {/* <Route path="auth/*" element={<AuthLayout />} /> */}
            <Route path="/*" element={<DashboardLayout />} />
            {/* <Route path="rtl/*" element={<RtlLayout />} /> */}
            {/* <Route path="/" element={<Navigate to="/admin" replace />} /> */}
          </Routes>
        </Authenticator>
    </>
  );
};

export default App;
