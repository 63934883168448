import { FaChevronDown } from "react-icons/fa";
import { Bars } from "react-loading-icons";
import { HiPencilSquare } from "react-icons/hi2";
import { useAuthenticator } from "@aws-amplify/ui-react";

import { IoIosRefresh } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { generateClient } from "aws-amplify/api";
import { getAllAssessments } from "graphql/queries";
import AssesmentCard from "components/card/AssesmentCard";
import { useEffect, useState } from "react";
import AddAssessmentModal from "./components/AddAssessmentModal";
import { getOrgAssessments } from "graphql/queries";
import { updateOrgAssessments } from "graphql/mutations";

import { useSelector } from "react-redux";
import { fetchUserAttributes } from "aws-amplify/auth";

const client = generateClient();

const Assessments = () => {
  const { user } = useAuthenticator();
  const [email, setEmail] = useState();
  const [orgAssessment, setOrgAssessment] = useState();
  const [assessment, setAssessment] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const orgId = useSelector((state) => state.organization.orgId);
  const orgName = useSelector((state) => state.organization.name);
  const adminEmails = useSelector((state) => state.organization.adminEmails);

  const fetchOrgAssessments = async () => {
    setLoading(true);
    const assessment = await client.graphql({
      query: getOrgAssessments,
      variables: {
        input: {
          orgId: orgId,
        },
      },
    });
    if (assessment.data.getOrgAssessments) {
      setOrgAssessment(assessment.data.getOrgAssessments);
      setLoading(false);
    }
  };

  const fetchAssessments = async () => {
    setLoading(true);
    // set fields to return from the query
    const assessment = await client.graphql({
      query: getAllAssessments,
    });

    if (assessment.data.getAllAssessments) {
      console.log("Assessment", assessment.data.getAllAssessments);

      setAssessment(assessment.data.getAllAssessments);
      setLoading(false);
    }
  };

  const updateOrg = async (assessmentsInput) => {
    await client.graphql({
      query: updateOrgAssessments,
      variables: {
        input: {
          orgId: orgId,
          assessments: assessmentsInput,
        },
      },
    });

    fetchOrgAssessments();
  };

  const handleDelete = async (key) => {
    // find the assessment to delete and return certain fields in a format
    const assessmentToDelete = orgAssessment
      .filter((assessment, index) => index === key)
      .map((assessment) => ({
        key: assessment.key,
        name: assessment.name,
        type: assessment.type,
        description: assessment.description,
        image: assessment.image,
        add: false,
      }));

    // update the org with the new assessment list
    await updateOrg(assessmentToDelete);
  };

  useEffect(() => {
    fetchOrgAssessments();
    fetchAssessments();

    const fetchUserDetails = async () => {
      const { email } = await fetchUserAttributes();
      setEmail(email);
    }
    fetchUserDetails();
  }, []);

  return (
    <>
      <div>
        <div className="mt-5 flex flex-row items-center justify-between">
          <h2 className="text-lg font-bold text-navy-700 dark:text-white">
            {orgName.toUpperCase()}
          </h2>
          {adminEmails.includes(user.username) ? (
            <div className="flex flex-row">
              <button
                onClick={() => setShowModal(true)}
                className="linear rounded-[20px] bg-brand-900 px-5 py-1 text-base font-medium text-white transition duration-200 hover:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-700"
              >
                Add Assessment
              </button>
            </div>
          ) : null}
        </div>
        <div className="my-4 flex flex-row flex-wrap gap-y-4 gap-x-3 items-start justify-center md:justify-start xl:justify-start wxl:justify-around bg-white px-3 lg:px-6 xl:px-8 py-[20px] dark:!bg-navy-800 dark:shadow-none dark:hover:!bg-navy-700">
          {loading ? (
            <Bars
              className="mt-4 mb-4 h-20 w-full items-center"
              fill="#D0CECF"
            />
          ) : (
            orgAssessment.map((assessment, key) => (
              <AssesmentCard
                key={key}
                title={assessment.name}
                label={assessment.type}
                image={assessment.image}
                extra="single-card"
              >
                {adminEmails.includes(user.username) ? (
                  <div className="flex flex-row justify-evenly">
                    {/* <button className="linear flex items-center rounded-[20px] bg-gray-200 p-1 px-3 text-base font-medium text-navy-700 transition duration-200 hover:bg-gray-300 dark:bg-brand-50 dark:text-brand-300 dark:hover:bg-brand-100">
                    <HiPencilSquare className="mr-1" /> Edit
                  </button> */}
                    <button
                      onClick={() => handleDelete(key)}
                      className="linear flex items-center rounded-[20px] bg-gray-200 p-1 px-3 text-base font-medium text-navy-700 transition duration-200 hover:bg-gray-300 dark:bg-brand-50 dark:text-brand-300 dark:hover:bg-brand-100"
                    >
                      <MdDelete className="mr-1" /> Delete
                    </button>
                  </div>
                ) : null}
              </AssesmentCard>
            ))
          )}
        </div>
        <button
          onClick={fetchOrgAssessments}
          className="flex w-full flex-row items-center justify-center rounded-[20px] bg-brand-400 py-2 text-white"
        >
          <IoIosRefresh className="mr-2" />
          Refresh
        </button>
      </div>

      {showModal ? (
        <AddAssessmentModal
          orgAssessments={orgAssessment}
          assessments={assessment}
          onSave={updateOrg}
          setShowModal={setShowModal}
          showModal={showModal}
        />
      ) : null}
    </>
  );
};

export default Assessments;
