import { IoHeart, IoHeartOutline } from "react-icons/io5";
import { useState } from "react";
import Card from "components/card";

const AssesmentCard = ({assesmentKey, title, label, image, result, extra, children  }) => {
  const [heart, setHeart] = useState(true);
  console.log("RESULT FROM ASSEST", result)
  return (
    <Card
      extra={`flex flex-col w-[250px] overflow-hidden whitespace-normal h-full !p-4 3xl:p-![18px] bg-white ${extra}`}
    >
      <div className="h-full w-full">
        <div className="dark:text-brand-400 font-bold text-md text-gray-300 text-center mb-2">{title}</div>
        <div className="relative w-full">
          <img
            src={image}
            className="mb-3 rounded-xl h-48 3xl:h-64 3xl:w-64 object-cover"
            alt=""
          />
          <button
            onClick={() => setHeart(!heart)}
            className="absolute top-3 right-3 flex items-center justify-center rounded-full bg-white p-2 text-brand-500 hover:cursor-pointer"
          >
            <div className="flex items-center justify-center rounded-full text-xl hover:bg-gray-50 dark:text-navy-900">
              {heart ? (
                <IoHeartOutline />
              ) : (
                <IoHeart className="text-brand-500" />
              )}
            </div>
          </button>
        </div>

        <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
          <div className="mb-2">
            <p className="flex flex-wrap mt-1 overflow-hidden text-sm dark:text-gray-800 text-white md:mt-2 font-bold ">
              {label}: {result}
            </p>
          </div>
        </div>

        {children}
      </div>
    </Card>
  );
};

export default AssesmentCard;
