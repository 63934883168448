import { TextField, useAuthenticator } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { generateClient } from "aws-amplify/api";
import { getUserProfile, getOrgAssessments } from "graphql/queries";
import { useDispatch, useSelector } from "react-redux";
import { setKey, setUserId, setProfileImage } from "../../store";
import { useLocation, useNavigate } from "react-router-dom";
import { Bars } from "react-loading-icons";
import { fetchUserAttributes } from "aws-amplify/auth";
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import Assessments from "views/assessments";
import AssesmentCard from "components/card/AssesmentCard";

const data = {
    "name": "Ecourse 1",
    "course_introduction": "https://api.slingacademy.com/v1/sample-data/files/just-text.pdf",
    "course_syllabus": "https://api.slingacademy.com/v1/sample-data/files/just-text.pdf",
    "course_videos": ["https://www.youtube.com/embed/jZzyERW7h1A?si=MI_yAaalH4aJIZ9S", "https://www.youtube.com/embed/jZzyERW7h1A?si=MI_yAaalH4aJIZ9S", "https://www.youtube.com/embed/jZzyERW7h1A?si=MI_yAaalH4aJIZ9S", "https://www.youtube.com/embed/jZzyERW7h1A?si=MI_yAaalH4aJIZ9S", "https://www.youtube.com/embed/jZzyERW7h1A?si=MI_yAaalH4aJIZ9S", "https://www.youtube.com/embed/jZzyERW7h1A?si=MI_yAaalH4aJIZ9S"],
    "course_assessments": [
        {
            "add": true,
            "image": "http://www.gospelcorneronline.com/wp-content/uploads/2016/02/hand412.jpg",
            "name": "Five Fold",
            "description": "Five Fold Calling Assessment",
            "type": "Biblical",
            "key": "Fivefold"
        },
        {
            "add": true,
            "image": "https://georgeclerie.files.wordpress.com/2012/09/1898-1-the-5-love-languages.jpg?w=128&h=128",
            "name": "Love Languages",
            "description": "Love Languages Assessment",
            "type": "Personality",
            "key": "LoveLang"
        },
        {
            "add": true,
            "image": "https://s3.amazonaws.com/iConverge/img/MBTI/mbti.jpg",
            "name": "Myers-Briggs Personality",
            "description": "Myers-Briggs Type Indicator Assessment",
            "type": "Personality",
            "key": "MBTI"
        },
        {
            "add": true,
            "image": "https://d1vaar1289l0xx.cloudfront.net/wp-content/uploads/2016/06/08_giver_icon.jpg",
            "name": "Spiritual Gifts",
            "description": "Spiritual gifts Assessment",
            "type": "Biblical",
            "key": "SpiritualGifts"
        }
    ],
};

const MyEcourse = () => {
    const [currentEcourse, setCurrentEcourse] = useState({})
    const ecourses = useSelector((state) => state.organization.ecourses);
    const ecourseKey = useSelector((state) => state.assessmentKey);


    const location = useLocation();
    const { edit, assessment } = location.state || {};

    const [openSections, setOpenSections] = useState({});

    const dispatch = useDispatch();


    const toggleSection = (index) => {
        setOpenSections(prev => ({
            ...prev,
            [index]: !prev[index]
        }));
    };

    const navigate = useNavigate();

    function assessmentClick(assessmentKey) {
        dispatch(setKey(assessmentKey));
        navigate("/current-assessment");
    }

    useEffect(() => {
        const currEcourse = ecourses.find(ecourse => ecourse.key === ecourseKey);
        if (!currEcourse) {
            navigate("/profile");
        }
        else {
            setCurrentEcourse(currEcourse)
        }
    }, []);

    return (
        <div>
            <div className="my-5 flex flex-row items-center justify-between">
                <h2 className="text-lg font-bold text-navy-700 dark:text-white">
                    {currentEcourse.name} {edit && "Edit"} {assessment}
                    {console.log("Ecourses from MyEcourse", ecourses)}
                </h2>
            </div>

            <div>
                {/* Course Introduction */}
                <h2>
                    <button
                        type="button"
                        className={`flex items-center justify-between w-full p-5 font-medium rtl:text-right ${openSections[0] ? 'text-gray-900 bg-white dark:text-white dark:bg-navy-800' : 'text-gray-500 bg-white dark:text-gray-400 dark:bg-navy-800'} border border-b-0 border-gray-200 dark:border-navy-700 hover:bg-white dark:hover:bg-navy-800 gap-3`}
                        onClick={() => toggleSection(0)}
                    >
                        <span>Course Introduction</span>
                        {openSections[0] ? <FaChevronUp /> : <FaChevronDown />}
                    </button>
                </h2>
                {openSections[0] && (
                    <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-navy-900">
                        <div className="pdf-container">
                            <iframe src={currentEcourse.introduction} width="100%" height="500px" />
                        </div>
                    </div>
                )}

                {/* Course Syllabus */}
                <h2>
                    <button
                        type="button"
                        className={`flex items-center justify-between w-full p-5 font-medium rtl:text-right ${openSections[1] ? 'text-gray-900 bg-white dark:text-white dark:bg-navy-800' : 'text-gray-500 bg-white dark:text-gray-400 dark:bg-navy-800'} border border-b-0 border-gray-200 dark:border-navy-700 hover:bg-white dark:hover:bg-navy-800 gap-3`}
                        onClick={() => toggleSection(1)}
                    >
                        <span>Course Syllabus</span>
                        {openSections[1] ? <FaChevronUp /> : <FaChevronDown />}
                    </button>
                </h2>
                {openSections[1] && (
                    <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-navy-900">
                        <div className="pdf-container">
                            <iframe src={currentEcourse.syllabus} width="100%" height="500px" />
                        </div>
                    </div>
                )}

                {/* Course Videos */}
                <h2>
                    <button
                        type="button"
                        className={`flex items-center justify-between w-full p-5 font-medium rtl:text-right ${openSections[2] ? 'text-gray-900 bg-white dark:text-white dark:bg-navy-800' : 'text-gray-500 bg-white dark:text-gray-400 dark:bg-navy-800'} border border-b-0 border-gray-200 dark:border-navy-700 hover:bg-white dark:hover:bg-navy-800 gap-3`}
                        onClick={() => toggleSection(2)}
                    >
                        <span>Course Videos</span>
                        {openSections[2] ? <FaChevronUp /> : <FaChevronDown />}
                    </button>
                </h2>
                {openSections[2] && (
                    <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-navy-900">
                        <div className="flex flex-wrap justify-center gap-4">
                                <div className="video-container p-2 m-2 rounded border border-gray-300 dark:border-gray-600">
                                    <iframe
                                        width="300"
                                        height="169"
                                        src={currentEcourse.video}
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin"
                                        allowFullScreen
                                        className="rounded"
                                    ></iframe>
                                </div>
                        </div>
                    </div>
                )}

                {/* Course Assessments */}
                <h2>
                    <button
                        type="button"
                        className={`flex items-center justify-between w-full p-5 font-medium rtl:text-right ${openSections[3] ? 'text-gray-900 bg-white dark:text-white dark:bg-navy-800' : 'text-gray-500 bg-white dark:text-gray-400 dark:bg-navy-800'} border border-b-0 border-gray-200 dark:border-navy-700 hover:bg-white dark:hover:bg-navy-800 gap-3`}
                        onClick={() => toggleSection(3)}
                    >
                        <span>Course Assessments</span>
                        {openSections[3] ? <FaChevronUp /> : <FaChevronDown />}
                    </button>
                </h2>
                {openSections[3] && (
                    <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-navy-900">

                        <div className="my-4 flex flex-row flex-wrap gap-y-4 gap-x-3 items-start justify-center md:justify-start xl:justify-start wxl:justify-around bg-white px-3 lg:px-6 xl:px-8 py-[20px] dark:!bg-navy-800 dark:shadow-none dark:hover:!bg-navy-700">
                            
                                <AssesmentCard
                                    assessmentKey={currentEcourse.key}
                                    title={currentEcourse.name}
                                    label={currentEcourse.type}
                                    image={currentEcourse.image}
                                    result={
                                        // userData?.questionData?.find(
                                        //     (userAssessment) => userAssessment.key === assessment.key
                                        // )?.result
                                        ""
                                    }
                                    extra="single-card"
                                >
                                    <div className="flex items-center justify-between md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col 2xl:items-start 3xl:flex-row 3xl:items-center 3xl:justify-between">
                                        <button
                                            onClick={() => assessmentClick(currentEcourse.key)}
                                            className="linear flex h-full w-full items-center justify-center rounded-[20px] bg-white px-4 py-2 text-base font-medium text-black transition duration-200 hover:bg-gray-200 active:bg-gray-400 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:opacity-90"
                                        >
                                            See Assessment
                                        </button>
                                    </div>
                                </AssesmentCard>
                            
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MyEcourse;
