import QuizOptionBox from "./components/QuizOptionBox";
import { generateClient } from "aws-amplify/api";
import { useEffect, useState } from "react";
import { getAssessment, getUserProfile } from "graphql/queries";
import { Bars } from "react-loading-icons";
import { useSelector } from "react-redux";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { updateUserProfile } from "graphql/mutations";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchUserAttributes } from "aws-amplify/auth";

const client = generateClient();

const CurrentAssessment = () => {
  const { user } = useAuthenticator();
  const navigate = useNavigate();
  const location = useLocation();
  const { ecourseKey } = location.state || {};

  const [userData, setUserData] = useState({});
  const [assessmentDetails, setAssessmentDetails] = useState({
    title: "",
    questionList: [],
    results: [],
    calculate: ""
  });

  const [questionNumber, setQuestionNumber] = useState(0);
  const [question, setQuestion] = useState("");
  const [answers, setAnswers] = useState([]);
  const [score, setScore] = useState({});
  const [selectedOption, setSelectedOption] = useState();
  const [choices, setChoices] = useState([null]);
  const [showBars, setShowBars] = useState(true);

  const assessmentKey = useSelector((state) => state.assessmentKey);
  const orgId = useSelector((state) => state.organization.orgId);

  useEffect(() => {
    const timer = setTimeout(() => setShowBars(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      const { email } = await fetchUserAttributes();
      const response = await client.graphql({
        query: getUserProfile,
        variables: { input: { email: email, orgName: orgId } }
      });

      const userProfile = response.data.getUserProfile;
      if (userProfile) {
        setUserData(userProfile);

        const questionData = userProfile.questionData.find(
          (data) => data.key === assessmentKey
        );
        if (questionData) {
          setQuestionNumber(questionData.questionNum);
          setScore(JSON.parse(questionData.totals));
          setChoices(questionData.questions);

          // navigate to the results page if the assessment is already completed
          if (questionData.questionNum + 1 === questionData.questions.length) {
            navigate("/assessment-results");
          }
        }
      }
    };
    fetchUserData();
  }, [user]);

  useEffect(() => {
    const fetchAssessment = async () => {
      const response = await client.graphql({
        query: getAssessment,
        variables: { input: { assessmentKey } }
      });

      const assessment = response.data.getAssessment;
      if (assessment) {
        console.log("ASSESSMETN, ", assessment)
        setAssessmentDetails({
          title: assessment.title,
          calculate: assessment.calculate,
          results: assessment.results,
          questionList: assessment.questions
        });
        setQuestion(assessment.questions[questionNumber].question);
        setAnswers(assessment.questions[questionNumber].answers);
      }
    };
    fetchAssessment();
  }, []);

  const handleSelect = (label, value, index) => {
    console.log("LABEL =>", label);
    console.log("VALUE => ", value);
    console.log("INDEX, ", index);

    setScore((prevScore) => ({
      ...prevScore,
      [label]: (prevScore[label] || 0) + value,
    }));
    setChoices([...choices.slice(0, questionNumber), index]);

    // Automatically proceed to the next question
    setSelectedOption(index);
    setTimeout(() => {
      nextQuestion();
    }, 300); // Adjust delay if necessary
  };

  const nextQuestion = () => {
    if (selectedOption === undefined) {
      return;
    };
    if (questionNumber < assessmentDetails.questionList.length - 1) {
      if (questionNumber % 5 === 0) {
        sendDataToDb();
      }

      setQuestionNumber((prevQuestionNumber) => {
        const nextNumber = prevQuestionNumber + 1;
        setQuestion(assessmentDetails.questionList[nextNumber].question);
        setAnswers(assessmentDetails.questionList[nextNumber].answers);

        if (nextNumber < choices.length && choices[nextNumber] !== null) {
          const nextChoice = choices[nextNumber];
          const nextValue = parseInt(assessmentDetails.questionList[nextNumber].answers[nextChoice].value.split("=")[1].slice(0, -1));
          const label = assessmentDetails.questionList[nextNumber].answers[nextChoice].value.split("=")[0].slice(1);
          setScore((nextScore) => ({
            ...nextScore,
            [label]: nextScore[label] + nextValue
          }));

          setSelectedOption(choices[nextNumber]);
        }

        return nextNumber;
      });
      setSelectedOption(undefined);

      console.log("SCORE, ", score);
      console.log("CHOICES, ", choices);
    } else {
      sendDataToDb(true);
      navigate("/assessment-results");  // Navigate to the results page
    }
  };

  const prevQuestion = () => {
    if (questionNumber > 0) {
      setQuestionNumber((prevQuestionNumber) => {
        const prevNumber = prevQuestionNumber - 1;
        setQuestion(assessmentDetails.questionList[prevNumber].question);
        setAnswers(assessmentDetails.questionList[prevNumber].answers);

        // update score
        const prevChoice = choices[prevNumber];
        const prevValue = parseInt(assessmentDetails.questionList[prevNumber].answers[prevChoice].value.split("=")[1].slice(0, -1));
        const label = assessmentDetails.questionList[prevNumber].answers[prevChoice].value.split("=")[0].slice(1);
        setScore((prevScore) => ({
          ...prevScore,
          [label]: prevScore[label] - prevValue
        }));

        setSelectedOption(choices[prevNumber]);  // Restore the selected option if any
        return prevNumber;
      });
    }
  };

  const sendDataToDb = async (completed = false) => {
    const calculateFunc = new Function('totals', assessmentDetails.calculate);
    const result = calculateFunc(score);
    const resultArray = result.split(", ")

    console.log("RESULT, ", resultArray)
    const { email } = await fetchUserAttributes();
    await client.graphql({
      query: updateUserProfile,
      variables: {
        // TODO: here modify the path 
        input: {
          orgName: orgId,
          email: email,
          questionData: {
            key: assessmentKey,
            questions: choices,
            questionNum: questionNumber,
            totals: JSON.stringify(score),
            result: completed ? result : "",
            results: resultArray
          }
        }
      }
    });
  };

  useEffect(() => {
    if (questionNumber === assessmentDetails.questionList.length) {
      sendDataToDb();
    }
  }, [questionNumber]);

  return (
    <div>
      {!showBars ? (
        <div className="mt-5 flex flex-row items-center justify-between">
          <h2 className="text-lg font-bold text-navy-700 dark:text-white">
            {assessmentDetails.title}
          </h2>
          <div className="rounded-full bg-black px-3 py-1 text-white dark:bg-white dark:text-black">
            Question {questionNumber + 1}/{assessmentDetails.questionList.length}
          </div>
        </div>
      ) : ""}
      {showBars ? (
        <Bars className="mt-4 mb-4 h-20 w-full items-center" fill="#D0CECF" />
      ) : (
        <div className="mt-5 flex h-full w-full flex-col items-start bg-white px-3 py-[20px] text-navy-700 dark:!bg-navy-800 dark:text-white dark:shadow-none dark:hover:!bg-navy-700">
          {questionNumber + 1}. {question}
          <div className="mt-4 flex w-full flex-col space-y-1.5 bg-gray-100 dark:bg-navy-700">
            <QuizOptionBox
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              answers={answers}
              handleSelect={handleSelect}
            />
          </div>
        </div>
      )}
      <div className="mt-8 items-center space-x-4 text-center">
        <button
          onClick={prevQuestion}
          className="linear rounded-[20px] bg-brand-900 px-8 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 dark:bg-white dark:text-brand-900 dark:hover:bg-gray-200"
        >
          Previous
        </button>
        <button
          id="next"
          onClick={nextQuestion}
          className="linear rounded-[20px] bg-brand-900 px-8 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
        >
          Next
        </button>
      </div>
    </div>
  );

};

export default CurrentAssessment;
