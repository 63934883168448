import { configureStore } from "@reduxjs/toolkit";
import { assessmentReducer, setKey } from "./slices/assessmentSlice";
import { organizationReducer, setOrgId, setOrgName, setAdminEmails, setRoutes, setEcourses } from "./slices/organizationSlice";
import { userReducer, setUserId, setProfileImage } from "./slices/userSlice";
import { ecourseReducer, setEcourseKey } from "./slices/ecourseSlice";

const store = configureStore({
  reducer: {
    assessmentKey: assessmentReducer,
    ecourseKey: ecourseReducer,
    organization: organizationReducer,
    user: userReducer,
  },
});

export { store, setKey, setOrgId, setOrgName, setAdminEmails, setUserId, setProfileImage, setRoutes, setEcourses, setEcourseKey};
