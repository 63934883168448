import { useState } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";

const BulkMessenger = () => {
  const [activeTab, setActiveTab] = useState(null);
  const [sendText, setSendText] = useState(false);
  const [sendVoice, setSendVoice] = useState(false);
  const [formData, setFormData] = useState({
    databaseResponse: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    fromNumber: '+16672255511',
    textMessage: '',
    csvFile: null,
    voiceMessage: null
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const jsonData = {
      token: 'rhlc.us-k3idjele9dk30'
    };

    for (const [key, value] of Object.entries(formData)) {
      if (value !== null && key !== 'csvFile' && key !== 'voiceMessage') {
        jsonData[key] = value;
      }
    }

    if (formData.csvFile) {
      try {
        const csvText = await formData.csvFile.text();
        jsonData['csvFile'] = csvText;
      } catch (error) {
        console.error('Error reading CSV file:', error);
        alert('Error reading CSV file: ' + error.message);
        return;
      }
    }

    if (formData.voiceMessage) {
      try {
        const voiceData = await formData.voiceMessage.arrayBuffer();
        const base64Voice = btoa(String.fromCharCode(...new Uint8Array(voiceData)));
        jsonData['voiceMessage'] = base64Voice;
      } catch (error) {
        console.error('Error reading voice file:', error);
        alert('Error reading voice file: ' + error.message);
        return;
      }
    }

    try {
      const response = await fetch('https://8c8kr2e767.execute-api.us-east-1.amazonaws.com/default/twillio_onboard_form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonData)
      });
      const result = await response.json();
      alert('Success: ' + JSON.stringify(result));
    } catch (error) {
      alert('Error: ' + error.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    
    if (name === 'csvFile' && files?.length > 0) {
      const file = files[0];
      if (!file.name.toLowerCase().endsWith('.csv')) {
        alert('Please select a CSV file');
        e.target.value = '';
        return;
      }
      
      const MAX_FILE_SIZE = 5 * 1024 * 1024;
      if (file.size > MAX_FILE_SIZE) {
        alert('File size should be less than 5MB');
        e.target.value = '';
        return;
      }
    }

    if (name === 'voiceMessage' && files?.length > 0) {
      const file = files[0];
      const MAX_VOICE_SIZE = 10 * 1024 * 1024;
      if (file.size > MAX_VOICE_SIZE) {
        alert('Voice file size should be less than 10MB');
        e.target.value = '';
        return;
      }
    }

    setFormData(prev => ({
      ...prev,
      [name]: files ? files[0] : value
    }));
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Bulk Messenger</h1>
      <div className="max-w-md mx-auto bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-xl font-semibold mb-6">Submit Your Information</h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="border rounded-lg overflow-hidden">
            <button
              type="button"
              onClick={() => setActiveTab(activeTab === 1 ? null : 1)}
              className="flex w-full justify-between items-center bg-gray-50 p-4 hover:bg-gray-100 transition-colors"
            >
              <span className="font-semibold">Upload Database Response</span>
              {activeTab === 1 ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </button>
            {activeTab === 1 && (
              <div className="p-4 border-t">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Select Database Response State
                </label>
                <select
                  name="databaseResponse"
                  onChange={handleInputChange}
                  className="w-full border rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value=""></option>
                  <option value="*">*</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                  <option value="unresponsive">Unresponsive</option>
                  <option value="unknown">Unknown</option>
                  <option value="more_info">More Info</option>
                </select>
              </div>
            )}
          </div>

          <div className="border rounded-lg overflow-hidden">
            <button
              type="button"
              onClick={() => setActiveTab(activeTab === 2 ? null : 2)}
              className="flex w-full justify-between items-center bg-gray-50 p-4 hover:bg-gray-100 transition-colors"
            >
              <span className="font-semibold">Upload CSV File</span>
              {activeTab === 2 ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </button>
            {activeTab === 2 && (
              <div className="p-4 border-t">
                <input
                  type="file"
                  accept=".csv"
                  name="csvFile"
                  onChange={handleInputChange}
                  className="w-full focus:outline-none"
                />
              </div>
            )}
          </div>

          <div className="border rounded-lg overflow-hidden">
            <button
              type="button"
              onClick={() => setActiveTab(activeTab === 3 ? null : 3)}
              className="flex w-full justify-between items-center bg-gray-50 p-4 hover:bg-gray-100 transition-colors"
            >
              <span className="font-semibold">Upload Single User</span>
              {activeTab === 3 ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </button>
            {activeTab === 3 && (
              <div className="p-4 border-t space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    onChange={handleInputChange}
                    className="w-full border rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    onChange={handleInputChange}
                    className="w-full border rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Phone Number</label>
                  <input
                    type="tel"
                    name="phoneNumber"
                    onChange={handleInputChange}
                    className="w-full border rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Email</label>
                  <input
                    type="email"
                    name="email"
                    onChange={handleInputChange}
                    className="w-full border rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
              </div>
            )}
          </div>

          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Select Phone Number for Campaign
              </label>
              <select
                name="fromNumber"
                onChange={handleInputChange}
                className="w-full border rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="+16672255511">+16672255511</option>
                <option value="+18333901867">+18333901867</option>
              </select>
            </div>

            <div>
              <div className="flex items-center mb-2">
                <input
                  type="checkbox"
                  id="sendText"
                  checked={sendText}
                  onChange={(e) => setSendText(e.target.checked)}
                  className="w-4 h-4 text-blue-600 rounded focus:ring-blue-500"
                />
                <label htmlFor="sendText" className="ml-2 text-sm font-medium text-gray-700">
                  Send Text
                </label>
              </div>
              <textarea
                name="textMessage"
                disabled={!sendText}
                onChange={handleInputChange}
                placeholder="Hello {firstName},"
                className="w-full border rounded-md p-2 min-h-32 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:bg-gray-100 disabled:text-gray-500"
              />
            </div>

            <div>
              <div className="flex items-center mb-2">
                <input
                  type="checkbox"
                  id="sendVoice"
                  checked={sendVoice}
                  onChange={(e) => setSendVoice(e.target.checked)}
                  className="w-4 h-4 text-blue-600 rounded focus:ring-blue-500"
                />
                <label htmlFor="sendVoice" className="ml-2 text-sm font-medium text-gray-700">
                  Send Voice
                </label>
              </div>
              <input
                type="file"
                name="voiceMessage"
                accept=".mp3"
                disabled={!sendVoice}
                onChange={handleInputChange}
                className="w-full focus:outline-none disabled:opacity-50"
              />
            </div>
          </div>

          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-3 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default BulkMessenger;