import React, { useState } from "react";
import { TextField, SelectField, Button } from "@aws-amplify/ui-react";
import { FieldArray, Formik, Form, Field } from 'formik';
import { generateClient } from "aws-amplify/api";
import { updateOrgProfile } from "graphql/mutations";
import { MultiSelect } from "./MultiSelect";
import { useDispatch } from "react-redux";
import { setRoutes } from "store";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3"; // Ensure this import matches your setup

const REGION = 'us-east-1';  
const BUCKET_NAME = 'backendstack-v2-imagesbucketnewc7a361fc-qjm1uixrilux'; 
// const IDENTITY_POOL_ID = 'us-east-1:53c59198-ae45-49d2-a52b-53fe1b86987f'; 

const s3 = new S3Client({
    region: REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    }
});
const client = generateClient();
const EditOrganizationModal = ({
  showModal,
  setShowModal,
  orgData,
  setUpdating,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [adminEmails, setAdminEmails] = useState(['']);
  
  const [logoImage, setLogoImage] = useState(null);
  const [logoImageName, setLogoImageName] = useState(null);
  const [titleImage, setTitleImage] = useState(null);
  const [titleImageName, setTitleImageName] = useState(null);

  const handleEmailChange = (index, event) => {
    const newAdminEmails = [...adminEmails];
    newAdminEmails[index] = event.target.value;
    setAdminEmails(newAdminEmails);
  };

  const handleAddEmail = () => {
    setAdminEmails([...adminEmails, '']);
  };

  const handleRemoveEmail = (index) => {
    const newAdminEmails = [...adminEmails];
    newAdminEmails.splice(index, 1);
    setAdminEmails(newAdminEmails);
  };

  const handleImageUpload = async (event, type) => {
    const file = event.target.files[0];
    if (!file) {
      alert('Please select a file to upload');
      return;
    }

    let currentVersion = "v0";
    const newVersion = `v${parseInt(currentVersion.slice(1)) + 1}`;
    const folderName = type === "logo" ? "logoUploads" : "titleUploads";
    const fileName = `${orgData.id.replace(/@/g, "_AT_").replace(/\./g, "_DOT_")}`;

    const params = {
      Bucket: BUCKET_NAME,
      Key: `${folderName}/${newVersion}/${fileName}`,
      Body: file,
      ContentType: "image/jpeg",
      CacheControl: 'no-cache, no-store, must-revalidate',
    };

    try {
      const command = new PutObjectCommand(params);
      await s3.send(command);
      const uploadUrl = `${process.env.REACT_APP_IMAGE_CLOUDFRONT_DISTRIBUTION}/${folderName}/${newVersion}/${fileName}`;
      if (type === "logo") {
        setLogoImage(uploadUrl);
        setLogoImageName(fileName);
      } else {
        setTitleImage(uploadUrl);
        setTitleImageName(fileName);
      }
    } catch (error) {
      console.error('Error uploading file: ', error);
    }
  };
  
  const handleSubmit = async (values) => {
    // Ensure both logo  and title images are uploaded whether they are new or not
    if ((!logoImage || !titleImage) && (!orgData.logoImage || !orgData.titleImage)) {
      alert("Please upload both logo and title images.");
      return;
    }

    setUpdating(false);
    setLoading(true);

    const updatedOrgData = await client.graphql({
      query: updateOrgProfile,
      variables: {
        input: {
          orgId: orgData.id,
          name: values.name,
          organizationType: values.organizationType,
          category: values.category,
          email: values.email,
          phone: values.phone,
          adminEmails: values.adminEmails,
          address: {
            street: values.street,
            city: values.city,
            state: values.state,
            zip: values.zip,
          },
          routes: values.routes,
          logoImage: logoImage,
          titleImage: titleImage,
        },
      },
    });

    setLoading(false);
    setShowModal(false);
    dispatch(setRoutes(values.routes));
    setUpdating(true);
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
            <div className="relative max-h-full w-full max-w-md p-4">
              <div className="relative rounded-lg bg-white shadow dark:bg-gray-700">
                <div className="flex items-center justify-between rounded-t border-b p-4 dark:border-gray-600 md:p-5">
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                    Organization Details
                  </h3>
                  <button
                    type="button"
                    onClick={() => setShowModal(false)}
                    className="inline-flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-400 ms-auto end-2.5 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-hide="authentication-modal"
                  >
                    <svg
                      className="h-3 w-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                <div className="p-4 md:p-5">
                  <Formik
                    initialValues={{
                      name: orgData.name,
                      organizationType: orgData.organizationType,
                      category: orgData.category,
                      email: orgData.email,
                      adminEmails: orgData.adminEmails,
                      phone: orgData.phone,
                      street: orgData.address.street,
                      city: orgData.address.city,
                      state: orgData.address.state,
                      zip: orgData.address.zip,
                      routes: orgData.routes,
                      logoImage: orgData.logoImage,
                      titleImage: orgData.titleImage,
                    }}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form
                        className="space-y-4 text-gray-900 dark:text-white"
                        onSubmit={handleSubmit}
                      >
                        <label className="block text-gray-700 dark:text-white text-sm sm:text-lg font-bold mb-1" htmlFor="name">
                          Organization Name
                        </label>
                        <TextField
                          name="name"
                          onChange={handleChange}
                          value={values.name}
                          extra="w-full text-gray-900 dark:text-white"
                        />
                        <label className="block text-gray-700 dark:text-white text-sm sm:text-lg font-bold mb-1" htmlFor="email">
                          Organization Email
                        </label>
                        <TextField
                          name="email"
                          onChange={handleChange}
                          value={values.email}
                          extra="w-full text-gray-900 dark:text-white"
                        />
                        <label className="block text-gray-700 dark:text-white text-sm sm:text-lg font-bold mb-1" htmlFor="phone">
                          Organization Phone
                        </label>
                        <TextField
                          name="phone"
                          onChange={handleChange}
                          value={values.phone}
                          extra="w-full text-gray-900 dark:text-white"
                        />
                        
                        <label className="block text-gray-700 dark:text-white text-sm sm:text-lg font-bold mb-1" htmlFor="logoImage">
                          Logo Image
                        </label>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => handleImageUpload(e, "logo")}
                        />
                        {logoImageName && (
                          <p className="text-gray-600 dark:text-gray-400 text-sm">
                            Selected File: {logoImageName}
                          </p>
                        )}
                        
                        <label className="block text-gray-700 dark:text-white text-sm sm:text-lg font-bold mb-1" htmlFor="titleImage">
                          Title Image
                        </label>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => handleImageUpload(e, "title")}
                        />
                        {titleImageName && (
                          <p className="text-gray-600 dark:text-gray-400 text-sm">
                            Selected File: {titleImageName}
                          </p>
                        )}
                        
                        <label className="block text-gray-700 dark:text-white text-sm sm:text-lg font-bold mb-1" htmlFor="adminEmails">
                          Organization Admins
                        </label>
                        <FieldArray name="adminEmails" >
                          {({ push, remove }) => (
                            <div className="text-gray-900 dark:text-white">
                              {values.adminEmails.map((email, index) => (
                                <div key={index} className="flex items-end space-x-4 mb-2">
                                  <Field
                                    name={`adminEmails[${index}]`}
                                    render={({ field }) => (
                                      <div className="flex-grow">
                                        <TextField
                                          {...field}
                                          label={`Admin Email ${index + 1}`}
                                          className="w-full text-gray-900 dark:text-white"
                                        />
                                      </div>
                                    )}
                                  />
                                  <Button
                                    onClick={() => remove(index)}
                                    className="ml-2"
                                    disabled={values.adminEmails.length === 1}
                                  >
                                    Delete
                                  </Button>
                                </div>
                              ))}
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => push('')}
                              >
                                Add Admin Email
                              </Button>
                            </div>
                          )}
                        </FieldArray>

                        <label className="block text-gray-700 dark:text-white text-sm sm:text-lg font-bold mb-1" htmlFor="routes">
                          Accessible Routes
                        </label>
                        <Field
                          name="routes"
                          options={[
                            { value: "profile", label: "Profile" },
                            { value: "videos", label: "Videos" },
                            { value: "organization", label: "Organization" },
                            // { value: "assessments", label: "Assessments" },
                            { value: "current-assessment", label: "Current Assessment" },
                            { value: "assessment-results", label: "Assessment Results" },
                            { value: "my-ecourse", label: "My E-Course" },
                            { value: "ecourse-creator", label: "E-Course Creator" },
                            { value: "messages", label: "Bulk Messenger" },
                          ]}
                          value={values.routes}
                          component={MultiSelect}
                          placeholder="Select Routes"
                          isMulti={true}
                        />

                        <label className="block text-gray-700 dark:text-white text-sm sm:text-lg font-bold mb-1" htmlFor="organizationType">
                          Organization Type
                        </label>
                        <SelectField
                          name="organizationType"
                          defaultValue={values.organizationType}
                          onChange={handleChange}
                        >
                          <option value="NonProfit">Non-Profit</option>
                          <option value="ForProfit">For-Profit</option>
                        </SelectField>

                        <label className="block text-gray-700 dark:text-white text-sm sm:text-lg font-bold mb-1" htmlFor="category">
                          Category
                        </label>
                        <SelectField
                          name="category"
                          defaultValue={values.category}
                          onChange={handleChange}
                        >
                          <option value="Church">Church</option>
                          <option value="Other">Other</option>
                        </SelectField>

                        <label className="block text-gray-700 dark:text-white text-sm sm:text-lg font-bold mb-1">
                          Address
                        </label>
                        <TextField
                          label="Street"
                          name="street"
                          onChange={handleChange}
                          value={values.street}
                          extra="w-full"
                        />
                        <TextField
                          label="City"
                          name="city"
                          onChange={handleChange}
                          value={values.city}
                          extra="w-full"
                        />
                        <TextField
                          label="State"
                          name="state"
                          onChange={handleChange}
                          value={values.state}
                          extra="w-full"
                        />
                        <TextField
                          label="ZipCode"
                          name="zip"
                          onChange={handleChange}
                          value={values.zip}
                          extra="w-full"
                        />
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className={` ${isSubmitting && "disabled"
                            } w-full rounded-md bg-brand-900 px-5 py-2.5 text-base font-medium text-white text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90`}
                        >
                          {isSubmitting ? "Updating..." : "Update"}
                        </button>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      ) : null}
    </>
  );
};

export default EditOrganizationModal;
