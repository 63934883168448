import Radio from "components/radio";
import { useState } from "react";

const RadioOption = (props) => {

    return (
        <div onClick={props.onClick} className="flex flex-row items-center text-black dark:text-white space-x-2 p-3 border-b-2 border-gray-200 dark:border-gray-700">
            <Radio checked={props.correct} color={props.color} id={props.children} name={props.children}/>
            <label for={props.children}>{props.children}</label><br></br>
        </div>
    )
}

export default RadioOption;