import { createSlice } from "@reduxjs/toolkit";

// create slice with key and userData as initial state
const ecourseSlice = createSlice({
    name: "ecourseKey",
    initialState: "",
    reducers: {
      setEcourseKey(state, action) {
        return action.payload;
      },
      
    },
  });
  
  export const { setEcourseKey } = ecourseSlice.actions;
  export const ecourseReducer = ecourseSlice.reducer;