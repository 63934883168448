import { createSlice } from "@reduxjs/toolkit";

// create slice with key and userData as initial state
const assessmentSlice = createSlice({
    name: "assessmentKey",
    initialState: "",
    reducers: {
      setKey(state, action) {
        return action.payload;
      },
      
    },
  });
  
  export const { setKey } = assessmentSlice.actions;
  export const assessmentReducer = assessmentSlice.reducer;