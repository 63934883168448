/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUserProfile = /* GraphQL */ `
  mutation CreateUserProfile($input: CreateUserProfileInput!) {
    createUserProfile(input: $input) {
      id
      firstName
      lastName
      email
      phoneNumber
      gender
      birthDate
      image
      questionData {
        key
        result
        questions
        questionNum
        totals
        chosenValues
        results
        __typename
      }
      __typename
    }
  }
`;
export const updateUserProfile = /* GraphQL */ `
  mutation UpdateUserProfile($input: UpdateUserProfileInput!) {
    updateUserProfile(input: $input) {
      id
      firstName
      lastName
      email
      phoneNumber
      gender
      birthDate
      image
      questionData {
        key
        result
        questions
        questionNum
        totals
        chosenValues
        results
        __typename
      }
      __typename
    }
  }
`;
export const updateOrgProfile = /* GraphQL */ `
  mutation UpdateOrgProfile($input: UpdateOrgProfileInput!) {
    updateOrgProfile(input: $input) {
      id
      name
      address {
        street
        city
        state
        zip
        __typename
      }
      organizationType
      category
      email
      phone
      adminEmails
      logoImage
      backgroundImage
      titleImage
      assessments {
        introduction
        syllabus
        video
        key
        name
        image
        description
        type
        __typename
      }
      routes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrgAssessments = /* GraphQL */ `
  mutation UpdateOrgAssessments($input: UpdateOrgAssessmentsInput!) {
    updateOrgAssessments(input: $input) {
      id
      name
      address {
        street
        city
        state
        zip
        __typename
      }
      organizationType
      category
      email
      phone
      adminEmails
      logoImage
      backgroundImage
      titleImage
      assessments {
        introduction
        syllabus
        video
        key
        name
        image
        description
        type
        __typename
      }
      routes
      createdAt
      updatedAt
      __typename
    }
  }
`;
