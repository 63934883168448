import React from "react";
import Select from "react-select";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: "1px solid #E5E5E5",
        color: state.isSelected ? "#fff" : "#333",
        backgroundColor: state.isSelected ? "#333" : "#fff",
        padding: 10,
    }),
    control: (provided) => ({
        ...provided,
        borderRadius: 0,
        borderColor: "#E5E5E5",
    }),
    multiValueRemove: (styles) => ({
        ...styles,
        color: "#333",
        ":hover": {
            color: "#333",
        },
    }),
    multiValue: (styles) => {
        return {
            ...styles,
            backgroundColor: "#f9f9f9",
        };
    },


};


export const MultiSelect = ({
    className,
    placeholder,
    field,
    form,
    options,
    isMulti = false
}) => {
    const onChange = (option) => {
        const value = isMulti
            ? option.map((item) => item.value)
            : option.value;

        form.setFieldValue(field.name, value);
    };

    const getValue = () => {
        if (!field.value) {
            return isMulti ? [] : "";
        }

        if (isMulti) {
            return options.filter((option) => field.value.includes(option.value));
        }

        return options.find((option) => option.value === field.value);
    };

    return (
        <Select
            name={field.name}
            value={getValue()}
            onChange={onChange}
            placeholder={placeholder}
            styles={customStyles}
            options={options}
            isMulti={isMulti}
            isSearchable={false}
        />
    );
};

